











































































































import Vue from "vue";
import { Forecast } from "@/interfaces/forecast";

export default Vue.extend({
  props: {
    latestPrices: Array as () => Forecast[],
    latestOtcPrice: Object as () => Forecast
  },
  data: function () {
    return {
      otcOffsets: [-10, -5, -3, -1, 0, 1, 3, 5, 10] as number[],
      productionOffsets: [-4000, -3000, -2000, -1000, 0, 1000, 2000, 3000, 4000] as number[],
      capacityOffsets: [0, -250, -500, -1000, -1500, -2000, -2500] as number[]
    };
  },
  computed: {
    pricesByOffset: function (): { [subproduct: string]: { [offset: number]: Forecast } } {
      const pricesByOffset = {} as { [subproduct: string]: { [offset: number]: Forecast } };
      ["OTC", "Residual Load", "Solar Photovoltaic", "Wind", "Hard Coal", "Lignite", "Oil", "Natural Gas", "Nuclear"].forEach((s: string) => {
        pricesByOffset[s] = this.getLatestPricesByOffset(s);
      });
      return pricesByOffset;
    }
  },
  methods: {
    getLatestPricesByOffset: function (label: string): { [offset: number]: Forecast } {
      const latestPrices = this.latestPrices.filter((f: Forecast) => f.subproductLabel === label);
      const latestPricesByOffset = {} as { [offset: number]: Forecast };
      latestPrices.forEach((f: Forecast) => {
        latestPricesByOffset[f.offsetLabel] = f;
      });
      latestPricesByOffset[0] = this.latestOtcPrice;
      return latestPricesByOffset;
    }
  }
});
