export interface CustomNetworkError {
  name: string;
  statusCode: number;
  url: string;
  method: string;
  content: string;
  message: string;
}

export function NetworkError (statusCode: number, url: string, method: string, content: string): CustomNetworkError {
  return {
    name: "NetworkError",
    statusCode: statusCode,
    url: url,
    method: method,
    content: content,
    message: `Custom Network Error: status ${statusCode} on ${method} request to "${url}". Received content: ${content}`
  };
}

export function Warning (name: string, message: string): PromiseRejectionEvent {
  return new PromiseRejectionEvent("warning", { promise: Promise.resolve(), reason: { name: name, message: message } });
}
