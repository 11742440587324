import { executeQuery } from "@/services/apiHelpers";
import { Forecast, LiveTrade, RiskIndicator, TraderValue } from "@/interfaces/forecast";
import { PlotsData } from "@/interfaces/performance";

class ApiClient {
  url: string;

  constructor (url: string) {
    this.url = url;
  }

  async testPassword (): Promise<boolean> {
    const country = "DE";
    const date = "2022-01-01";
    const result = await executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/predictions?prediction_target_date=" + date, true);
    return result != null;
  }

  async getData (country: string, date: string, mode: string): Promise<Forecast[]> {
    return executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/predictions?prediction_target_date=" + date + "&mode=" + mode, true);
  }

  async getPlotsData (country: string, start: string, end: string, time: string): Promise<PlotsData> {
    return executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/backtest/plots?time=" + time + "&start=" + start + "&end=" + end, true);
  }

  async getSpots (country: string, start: string, end: string): Promise<{[date: string]: number}> {
    return executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/spot?start_date=" + start + "&end_date=" + end, true);
  }

  async postTrade (country: string, trade: LiveTrade): Promise<void> {
    const backendTrade = {
      position: trade.position,
      volume: parseFloat(trade.volume.toString()),
      price: parseFloat(trade.price.toString()),
      time: trade.time,
      date: trade.date
    };
    return executeQuery(this.url, "POST", "/power/v1/power/dayahead/" + country + "/trades", true, backendTrade);
  }

  async postReason (country: string, reason: LiveTrade): Promise<void> {
    const backendReason = {
      position: "none",
      volume: null,
      price: null,
      time: reason.time,
      date: reason.date,
      reasons: reason.reasons
    };
    return executeQuery(this.url, "POST", "/power/v1/power/dayahead/" + country + "/trades", true, backendReason);
  }

  async getTrades (country: string, date: string): Promise<LiveTrade[]> {
    return executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/trades?date=" + date, true);
  }

  async getRiskIndicators (country: string, date: string, mode: string): Promise<RiskIndicator[]> {
    return executeQuery(this.url, "GET", "/power/v1/power/dayahead/" + country + "/riskIndicators?date=" + date + "&mode=" + mode, true);
  }

  async postTraderValue (country: string, traderValue: TraderValue): Promise<void> {
    return executeQuery(this.url, "POST", "/power/v1/power/dayahead/" + country + "/traderValue", true, traderValue);
  }

  async sendToSupport (contact: string, isUrgent: boolean, text: string): Promise<boolean> {
    return executeQuery(this.url, "POST", "/power/v1/support", true, { contact, isUrgent, text });
  }
}

const apiClient = new ApiClient(process.env.VUE_APP_BACKEND_API_BASE_URL ?? "");

export default apiClient;
