import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jQuery from "jquery";

declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
    refreshInterval: number;
  }
}

window.$ = window.jQuery = jQuery;
require("../semantic/dist/semantic.js");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
