












































































































































import Vue from "vue";
import { LiveTrade, Reason } from "@/interfaces/forecast";
import apiClient from "@/services/apiClient";
import { formatDate } from "@/utils";
import "@/directives/dropdown";

export default Vue.extend({
  name: "LiveTrades",
  props: {
  },
  data: function () {
    return {
      loading: false,
      country: "",
      date: "",
      trades: [] as LiveTrade[],
      reason: null as unknown as LiveTrade,
      reasons: {
        "model recommendation": "Model recommendation \"Do not trade\"",
        "low liquidity": "Low liquidity",
        "high volatility": "High volatility",
        "high spread": "High spread",
        "risky market": "Risky market",
        "order not matched": "Order not matched",
        other: "Other"
      } as { [key: string]: string },
      needsExplanation: ["risky market", "order not matched", "other"]
    };
  },
  computed: {
    nonEmptyTrades: function (): LiveTrade[] {
      const trades = this.trades.filter((trade: LiveTrade) => parseFloat(trade.volume.toString()) !== 0 || !trade.saved);
      trades.sort((a: LiveTrade, b: LiveTrade) => {
        return b.time.localeCompare(a.time);
      });
      return trades;
    }
  },
  methods: {
    addReason: function (): void {
      this.reason = {
        position: "none",
        volume: null as unknown as number,
        price: null as unknown as number,
        time: "12:00",
        date: this.date,
        reasons: [{
          reason: null as unknown as string,
          explanation: null as unknown as string
        }],
        editing: true,
        editedPosition: "none",
        editedVolume: null as unknown as number,
        editedPrice: null as unknown as number,
        hoursTime: null as unknown as string,
        minutesTime: null as unknown as string,
        deleting: false,
        saved: false
      };
    },
    saveReason: async function (): Promise<void> {
      this.reason.editing = false;
      (this.reason.reasons as Reason[]).forEach((r: Reason) => {
        if (this.needsExplanation.indexOf(r.reason) < 0) {
          r.explanation = null as unknown as string;
        }
      });
      await apiClient.postReason(this.country, this.reason);
      this.reason.saved = true;
    },
    addNewTrade: function (): void {
      this.trades.unshift({
        position: "",
        volume: 0,
        price: 0,
        time: "12:00",
        date: this.date,
        editing: true,
        editedPosition: "",
        editedVolume: 0,
        editedPrice: 0,
        hoursTime: "8",
        minutesTime: "0",
        deleting: false,
        saved: false
      });
    },
    setEditableFields: function (trade: LiveTrade): void {
      trade.hoursTime = parseFloat(trade.time.split(":")[0]).toString();
      trade.minutesTime = parseFloat(trade.time.split(":")[1]).toString();
      trade.editedPosition = trade.position;
      trade.editedVolume = trade.volume;
      trade.editedPrice = trade.price;
    },
    editTrade: function (trade: LiveTrade): void {
      if (!trade.editing) {
        this.setEditableFields(trade);
        trade.editing = true;
      } else if (trade.saved) {
        this.setEditableFields(trade);
        trade.editing = false;
      } else {
        const index = this.trades.indexOf(trade);
        this.trades.splice(index, 1);
      }
    },
    saveTrade: async function (trade: LiveTrade): Promise<void> {
      if (trade.editedPosition.length > 0 && trade.editedPrice > 0 && trade.editedVolume > 0) {
        this.loading = true;
        trade.position = trade.editedPosition;
        trade.volume = trade.editedVolume;
        trade.price = trade.editedPrice;
        const time = (trade.hoursTime.toString().length > 1 ? trade.hoursTime.toString() : "0" + trade.hoursTime.toString()) + ":" + (trade.minutesTime.toString().length > 1 ? trade.minutesTime.toString() : "0" + trade.minutesTime.toString());
        if (trade.saved && time !== trade.time) {
          this.deleteTrade({ ...trade });
        }
        trade.time = time;
        await apiClient.postTrade(this.country, trade);
        trade.saved = true;
        trade.editing = false;
        this.loading = false;
      }
    },
    deleteTrade: async function (trade: LiveTrade): Promise<void> {
      this.loading = true;
      trade.volume = 0;
      await apiClient.postTrade(this.country, trade);
      this.loading = false;
    },
    getTrades: async function (): Promise<void> {
      this.loading = true;
      const trades = await apiClient.getTrades(this.country, this.date);
      const reasons = trades.filter((t) => t.reasons != null);
      if (reasons.length > 0) {
        reasons[0].editing = false;
        reasons[0].saved = true;
        this.reason = reasons[0];
      } else {
        trades.forEach((trade: LiveTrade) => {
          trade.editing = false;
          this.setEditableFields(trade);
          trade.saved = true;
          trade.deleting = false;
        });
        this.trades = trades;
      }
      this.loading = false;
    }
  },
  mounted: function () {
    this.country = this.$route.params.country;
    this.date = formatDate(new Date());
    this.getTrades();
  }
});

