













































































































import Vue from "vue";
import { Forecast, RiskIndicator } from "@/interfaces/forecast";
import { PlotsData, PnL, Matrix } from "@/interfaces/performance";
import apiClient from "@/services/apiClient";
import { formatDate } from "@/utils";
import PNLChart from "@/components/PNLChart.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import LiveTrades from "@/components/LiveTrades.vue";
import DashboardTable from "@/components/DashboardTable.vue";
import ScenariosTables from "@/components/ScenariosTables.vue";
import "@/directives/calendar";

export default Vue.extend({
  name: "PowerDayAhead",
  components: {
    PNLChart,
    ConfusionMatrix,
    LiveTrades,
    DashboardTable,
    ScenariosTables
  },
  data: function () {
    return {
      tab: "dashboard" as string,
      country: "" as string,
      loading: false as boolean,
      date: null as unknown as Date,
      data: [] as Forecast[],
      loadingPlots: false as boolean,
      startDate: "" as string,
      endDate: "" as string,
      plotsTime: "10:00" as string,
      plots: null as unknown as PlotsData,
      spots: null as unknown as { [date: string]: number },
      riskIndicators: [] as RiskIndicator[],
      intervalId: 0 as number,
      traderPredictions: [] as Forecast[],
      traderValue: 0 as number
    };
  },
  computed: {
    baselineOtcPrices: function (): Forecast[] {
      return this.data.filter((f: Forecast) => f.subproductLabel === "default");
    },
    latestPrices: function (): Forecast[] {
      const latestPredictedTime = this.data.length > 0 ? this.data[0].predictedTime : "";
      return this.data.filter((f: Forecast) => f.predictedTime === latestPredictedTime);
    },
    noDataError: function (): boolean {
      return !this.loading && this.date != null && this.date.getUTCHours() + Math.abs(this.date.getTimezoneOffset() / 60) >= 8 && this.data.length === 0;
    },
    normalizedPlots: function (): PlotsData {
      if (this.plots === null) {
        return null as unknown as PlotsData;
      }
      const normalizedPnl = this.normalizePnL(this.plots.pnl);
      const normalizedTraderPnl = this.normalizePnL(this.plots.trader_pnl);
      return { pnl: normalizedPnl, trader_pnl: normalizedTraderPnl, confusionMatrix: null as unknown as Matrix };
    }
  },
  methods: {
    loadData: async function () {
      this.loading = true;
      this.date = new Date();
      const tomorrowsDate = this.getTomorrowsDate();
      const data = await apiClient.getData(this.country, formatDate(tomorrowsDate), "default");
      const traderPredictions = await apiClient.getData(this.country, formatDate(tomorrowsDate), "ondemand");
      await this.loadSpots();
      data.sort((f1: Forecast, f2: Forecast) => f2.predictedTime.localeCompare(f1.predictedTime));
      traderPredictions.sort((f1: Forecast, f2: Forecast) => f2.predictedTime.localeCompare(f1.predictedTime));
      this.data = data;
      this.traderPredictions = traderPredictions;
      this.loading = false;
    },
    loadPlotsData: async function () {
      this.loadingPlots = true;
      const plots = await apiClient.getPlotsData(this.country, this.startDate, this.endDate, this.plotsTime);
      if (plots.pnl.length > 0) {
        plots.pnl = this.fillAndSortPnL(plots.pnl, this.startDate, this.endDate);
        plots.trader_pnl = this.fillAndSortPnL(plots.trader_pnl, this.startDate, this.endDate);
      }
      this.plots = plots;
      this.loadingPlots = false;
    },
    getTomorrowsDate: function () {
      const tomorrowsDate = new Date();
      tomorrowsDate.setDate(tomorrowsDate.getDate() + 1); // get tomorrow's date
      return tomorrowsDate;
    },
    loadSpots: async function () {
      const spots = await apiClient.getSpots(this.country, formatDate(this.date), formatDate(this.getTomorrowsDate()));
      this.spots = spots;
    },
    fillAndSortPnL: function (pnl: PnL[], start: string, end: string): PnL[] {
      let checkedDate = new Date(start);
      const endDate = new Date(end);
      while (+checkedDate < +endDate) {
        const checkedDateString = formatDate(checkedDate);
        if (pnl.filter((d: PnL) => d.date === checkedDateString).length === 0) {
          pnl.push({
            date: checkedDateString,
            daily_pnl: null as unknown as number
          } as PnL);
        }
        checkedDate = new Date(checkedDate.setDate(checkedDate.getDate() + 1));
      }
      return pnl.sort((a: PnL, b: PnL) => a.date.localeCompare(b.date));
    },
    normalizePnL: function (pnl: PnL[]): PnL[] {
      return pnl.map((p: PnL) => {
        return { date: p.date, daily_pnl: p.volume > 0 && p.daily_pnl != null ? p.daily_pnl / (p.volume * 24) : p.daily_pnl, volume: p.volume } as PnL;
      });
    },
    setStartDate: function (date: Date) {
      this.startDate = formatDate(date);
    },
    setEndDate: function (date: Date) {
      this.endDate = formatDate(date);
    },
    sendTraderValue: async function () {
      this.loading = true;
      const traderValue = {
        date: formatDate(this.date),
        value: this.traderValue,
        feature: "OTC"
      };
      await apiClient.postTraderValue(this.country, traderValue);
      const interval = setInterval(async () => {
        const traderPredictions = await apiClient.getData(this.country, formatDate(this.getTomorrowsDate()), "ondemand");
        if (this.traderPredictions.length < traderPredictions.length) {
          traderPredictions.sort((f1: Forecast, f2: Forecast) => f2.predictedTime.localeCompare(f1.predictedTime));
          this.traderPredictions = traderPredictions;
          this.loading = false;
          clearInterval(interval);
        }
      }, 2000);
      this.traderValue = 0;
    },
    getRiskIndicators: async function () {
      const riskIndicators = await apiClient.getRiskIndicators(this.country, formatDate(new Date()), "default");
      if (this.noDataError) {
        const traderRiskIndicators = await apiClient.getRiskIndicators(this.country, formatDate(new Date()), "ondemand");
        const dailyVol = riskIndicators.find((r: RiskIndicator) => r?.riClass === "Daily Volatility");
        const traderDailyVol = traderRiskIndicators.find((r: RiskIndicator) => r?.riClass === "Daily Volatility");
        if (dailyVol) {
          dailyVol.zScore = traderDailyVol?.zScore as number;
        } else if (traderDailyVol) {
          riskIndicators.unshift(traderDailyVol);
        }
      }
      this.riskIndicators = riskIndicators;
    }
  },
  watch: {
    startDate: function () {
      this.loadPlotsData();
    },
    endDate: function () {
      this.loadPlotsData();
    },
    plotsTime: function () {
      this.loadPlotsData();
    },
    tab: function (newTab) {
      if (this.$route.params.tab !== newTab) {
        this.$router.push({ params: { tab: newTab } });
      }
      if (newTab === "dashboard" || newTab === "scenarios") {
        this.loadData();
      } else if (newTab === "performance") {
        this.loadPlotsData();
      }
    }
  },
  mounted: async function () {
    this.country = this.$route.params.country;
    if (this.$route.params.tab != null) {
      this.tab = this.$route.params.tab;
    }
    const today = new Date();
    this.endDate = formatDate(today);
    today.setMonth(today.getMonth() - 2);
    this.startDate = (this.country === "FR" && +today < +(new Date("2022-05-09"))) ? "2022-05-09" : formatDate(today);
    await this.loadData();
    await this.getRiskIndicators();
    this.intervalId = setInterval(async () => {
      await this.getRiskIndicators();
    }, 15000) as unknown as number;
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId);
  }
});
