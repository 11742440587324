









































































import Vue from "vue";
import { PnL } from "@/interfaces/performance";
import * as d3 from "d3";
import { Selection } from "d3-selection";
import { ScaleLinear } from "d3-scale";
import { Line } from "d3-shape";
import "@/directives/checkbox.ts";
import { ScaleTime } from "d3";
import { formatDate } from "@/utils";

export default Vue.extend({
  name: "PNLChart",
  props: {
    onlyTrades: Boolean,
    pnl: Array as () => PnL[],
    traderPnl: Array as () => PnL[]
  },
  data: function () {
    return {
      showModel: true,
      showReal: true,
      margin: {
        top: 10 as number,
        left: 100 as number,
        right: 40 as number,
        bottom: 30 as number
      },
      vizWidth: 800 as number,
      vizHeight: 400 as number,
      y: null as unknown as ScaleLinear<number, number, number>,
      x: null as unknown as ScaleTime<number, number, never>,
      line: null as unknown as Line<[number, number]>,
      bandwidth: 0 as number,
      hover: false as boolean,
      hovered: null as unknown as PnL,
      hoveredTrader: null as unknown as PnL
    };
  },
  computed: {
    height: function (): number {
      return this.vizHeight + this.margin.bottom;
    },
    width: function (): number {
      return this.vizWidth + this.margin.right;
    },
    dates: function (): Date[] {
      return this.pnl.map((d: PnL) => new Date(d.date));
    },
    isTwoPlots: function (): boolean {
      return this.showModel && this.showReal;
    },
    barWidth: function (): number {
      return this.isTwoPlots ? this.bandwidth / 2 : this.bandwidth;
    },
    cumulativePnLDates: function (): string[] {
      const currentDate = new Date();
      return this.pnl.filter((d: PnL) => {
        const date = new Date(d.date);
        date.setUTCHours(12);
        return d.daily_pnl != null || +date < +currentDate;
      }).map((d: PnL) => d.date);
    },
    cumulativePnL: function (): PnL[] {
      return this.getCumulativePnL(this.pnl);
    },
    cumulativeTraderPnL: function (): PnL[] {
      return this.getCumulativePnL(this.traderPnl);
    },
    valuesDomain: function (): number[] {
      let cumulativeValues = this.showModel ? this.cumulativePnL.concat(this.pnl) : this.cumulativeTraderPnL.concat(this.traderPnl);
      if (this.isTwoPlots) {
        cumulativeValues = this.cumulativePnL.concat(this.pnl).concat(this.cumulativeTraderPnL).concat(this.traderPnl);
      }
      const values = cumulativeValues.map((d: PnL) => d.daily_pnl);
      const max = d3.max(values) as number;
      const min = d3.min(values) as number;
      const totalRange = Math.abs(max) + Math.abs(min);
      return [max < 0 ? totalRange / 5 : max + totalRange / 5, min > 0 ? -totalRange / 5 : min - totalRange / 5];
    }
  },
  methods: {
    draw: function () {
      this.bandwidth = (this.vizWidth - this.margin.right - this.margin.left) / this.pnl.length / 2;
      const x = d3.scaleUtc().domain([d3.min(this.dates) as Date, d3.max(this.dates) as Date]).range([this.margin.left, this.vizWidth - this.bandwidth]);
      const y = d3.scaleLinear().domain(this.valuesDomain).range([this.margin.top, this.vizHeight]);
      const yAxis = (g: Selection<any, any, any, any>) => g.attr("transform", `translate(${this.margin.left},0)`).call(d3.axisLeft(y).tickSizeOuter(0).ticks(5)); // eslint-disable-line @typescript-eslint/no-explicit-any
      const xAxis = (g: Selection<any, any, any, any>) => g.attr("transform", `translate(${this.bandwidth / 2},${this.height - this.margin.bottom})`).call(d3.axisBottom(x).ticks(Math.min(10, this.pnl.length), "%b %d").tickSizeOuter(0)); // eslint-disable-line @typescript-eslint/no-explicit-any

      const svg = d3.select(this.$el.querySelector("svg"));
      svg.select("#yAxis").selectAll("*").remove();
      svg.select("#yAxis").call(yAxis).selectAll(".tick").append("line").attr("x2", (this.vizWidth - this.margin.right).toString()).attr("x1", "5").attr("stroke", "#bbb").attr("stroke-dasharray", "5,5");

      svg.select("#xAxis").selectAll("*").remove();
      svg.select("#xAxis").call(xAxis);
      svg.select("#xAxis").selectAll("path").attr("stroke", "transparent");
      const uniqueTicks = [] as string[];
      svg.select("#xAxis").selectAll(".tick").each(function (d: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (uniqueTicks.indexOf(formatDate(d)) < 0) {
          uniqueTicks.push(formatDate(d));
        } else {
          d3.select(this).remove();
        }
      });
      svg.select("#yAxis").selectAll("path").attr("stroke", "transparent");
      svg.selectAll(".axis text").attr("font-size", "1.33em");

      svg.select("#yAxis").append("text").attr("fill", "#000").attr("transform", "rotate(-90)").attr("y", -80).attr("x", -this.vizHeight / (this.onlyTrades ? 3 : 2.5)).attr("font-size", "1.5em").text(this.onlyTrades ? "Honma Alpha PnL [€]" : "PnL [€/MWh]");

      this.y = y;
      this.x = x;
      this.line = d3.line().curve(d3.curveLinear).x((d: any) => x(new Date(d.date)) as number).y((d: any) => y(d.daily_pnl)); // eslint-disable-line @typescript-eslint/no-explicit-any
      svg.on("pointerenter", this.pointerentered).on("pointermove", this.pointermoved).on("pointerleave", this.pointerleft);
    },
    resize: function () {
      const rect = this.$el.getBoundingClientRect();
      this.vizWidth = Math.min(1000, rect.width - 200);
    },
    pointermoved: function (event: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      const xm = d3.pointer(event)[0];
      this.hovered = d3.least(this.cumulativePnL, (i: PnL) => Math.abs(this.x(new Date(i.date)) as number + (this.bandwidth / 2) - xm)) as PnL;
      this.hoveredTrader = this.cumulativeTraderPnL.filter((i: PnL) => i.date === this.hovered.date)[0];
    },
    pointerentered: function () {
      this.hover = true;
    },
    pointerleft: function () {
      this.hover = false;
    },
    getCumulativePnL: function (pnl: PnL[]): PnL[] {
      const filledPnl = pnl.filter((d: PnL) => this.cumulativePnLDates.indexOf(d.date) > -1).map((d: PnL) => {
        return {
          date: d.date,
          daily_pnl: d.daily_pnl != null ? d.daily_pnl : 0
        } as PnL;
      });
      return filledPnl.map((d: PnL, i: number) => {
        return {
          date: d.date,
          daily_pnl: filledPnl.slice(0, i + 1).map((p: PnL) => p.daily_pnl).reduce((previous: number, current: number) => previous + current)
        } as PnL;
      });
    }
  },
  watch: {
    width: function () {
      this.draw();
    },
    height: function () {
      this.draw();
    },
    isTwoPlots: function () {
      this.draw();
    },
    pnl: function () {
      this.draw();
    }
  },
  mounted: function () {
    this.showModel = !this.onlyTrades;
    window.addEventListener("resize", this.resize);
    this.resize();
    this.draw();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resize);
  }
});

