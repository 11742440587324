import { NetworkError } from "@/interfaces/customError";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function executeQuery (serverUrl: string, verb: string, path: string, json = true, requestContent: any = null): Promise<any> {
  const url = serverUrl + path;
  const token = sessionStorage.getItem("forecasting-ui") as string;

  let options = {
    method: verb,
    headers: {
      "Ocp-Apim-Subscription-Key": token,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  } as RequestInit;
  if (requestContent != null) {
    options = { ...options, body: JSON.stringify(requestContent) };
  }
  const res = await fetch(url, options).catch(e => {
    console.log(e);
    throw NetworkError(0, url, verb, "Failed request because of a network error, please contact the support.");
  });

  const content = (async () => {
    if (json && res.ok) {
      return res.json();
    } else {
      return res.text();
    }
  })();

  if (!res.ok) {
    console.log(res.status, url, verb, JSON.stringify(await content));
    throw NetworkError(res.status, url, verb, JSON.stringify(await content));
  }

  return content;
}
