






























































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import "@/directives/dropdown.ts";

export default Vue.extend({
  data: function () {
    return {
      passwordField: "" as string,
      loading: false as boolean,
      wrongPassword: false as boolean,
      validated: false as boolean,
      openSubmenu: true as boolean
    };
  },
  methods: {
    enterPassword: function () {
      this.wrongPassword = false;
      if (this.passwordField.trim().length === 0) {
        this.wrongPassword = true;
        return;
      }
      sessionStorage.setItem("forecasting-ui", this.passwordField.trim());
      this.validate();
    },
    validate: function () {
      this.loading = true;
      apiClient.testPassword().then(() => {
        this.loading = false;
        this.validated = true;
        const route = this.$route.path;
        if (route.length < 2) {
          location.hash = "/power/DE";
        }
      }).catch(() => {
        this.loading = false;
        this.wrongPassword = true;
      });
    },
    logout: function () {
      sessionStorage.removeItem("forecasting-ui");
      location.reload();
    }
  },
  mounted: function () {
    const token = sessionStorage.getItem("forecasting-ui") as string;
    if (token != null) {
      this.validate();
    }
  }
});

