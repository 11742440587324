import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import PowerDayAhead from "@/views/PowerDayAhead.vue";
import Support from "@/views/Support.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/power/:country/:tab?",
    name: "PowerDayAhead",
    component: PowerDayAhead
  },
  {
    path: "/support",
    name: "Support",
    component: Support
  }
];

const router = new VueRouter({
  routes
});

export default router;
