import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    errors: [] as PromiseRejectionEvent[]
  },
  mutations: {
    addError: function (state, error: PromiseRejectionEvent) {
      console.log(error);
      if (state.errors.find(e => e.reason.message === error.reason.message) == null) {
        state.errors.unshift(error);
      }
    },
    removeError: function (state, index: number) {
      state.errors.splice(index, 1);
    },
    removeAllErrors: function (state) {
      state.errors = [];
    }
  },
  actions: {
  },
  modules: {
  }
});

window.addEventListener("error", (event) => {
  store.commit("addError", event);
});
window.addEventListener("unhandledrejection", (event) => {
  store.commit("addError", event);
});
window.addEventListener("warning", (event) => {
  store.commit("addError", event);
});
Vue.config.errorHandler = function (error) {
  store.commit("addError", new PromiseRejectionEvent("vue-error", { promise: Promise.resolve(), reason: error }));
};

export default store;
