import Vue from "vue";
import { FomanticElement } from "@/interfaces/fomantic";

const Checkbox: Vue.DirectiveOptions = {
  inserted: function (el: HTMLElement) {
    ($(el) as FomanticElement).checkbox();
  } as Vue.DirectiveFunction
};

Vue.directive("checkbox", Checkbox);
