











































import Vue from "vue";
import apiClient from "@/services/apiClient";
import "@/directives/checkbox.ts";

export default Vue.extend({
  name: "Support",
  data: function () {
    return {
      loading: false as boolean,
      contact: "" as string,
      isUrgent: false as boolean,
      text: "" as string,
      success: false as boolean
    };
  },
  methods: {
    sendToSupport: async function (): Promise<void> {
      this.loading = true;
      await apiClient.sendToSupport(this.contact, this.isUrgent, this.text);
      this.loading = false;
      this.success = true;
    }
  }
});
