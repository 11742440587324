import Vue from "vue";
import { FomanticElement, CalendarDescriptor } from "@/interfaces/fomantic";
import { DirectiveBinding } from "vue/types/options";

const Calendar: Vue.DirectiveOptions = {
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    const options = {
      type: "date",
      minDate: binding.value.minDate,
      maxDate: binding.value.maxDate,
      initialDate: binding.value.initialDate,
      onSelect: (date: Date) => {
        binding.value.onSelect(date);
      }
    } as CalendarDescriptor;
    if (binding.value.startCalendar) {
      options.startCalendar = $(binding.value.startCalendar);
    }
    if (binding.value.endCalendar) {
      options.endCalendar = $(binding.value.endCalendar);
    }
    ($(el) as FomanticElement).calendar(options);
  } as Vue.DirectiveFunction
};

Vue.directive("calendar", Calendar);
