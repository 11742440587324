































import Vue from "vue";
import { Matrix } from "@/interfaces/performance";
import * as d3 from "d3";
import { Selection } from "d3-selection";
import { ScaleBand, ScaleSequential } from "d3-scale";

export default Vue.extend({
  name: "ConfusionMatrix",
  props: {
    matrix: Object as () => Matrix
  },
  data: function () {
    return {
      margin: {
        top: 20 as number,
        left: 50 as number,
        right: 50 as number,
        bottom: 40 as number
      },
      vizWidth: 400 as number,
      y: null as unknown as ScaleBand<string>,
      x: null as unknown as ScaleBand<string>,
      color: null as unknown as ScaleSequential<string, never>,
      bandwidth: 0 as number
    };
  },
  computed: {
    height: function (): number {
      return this.vizWidth + this.margin.bottom;
    },
    width: function (): number {
      return this.vizWidth + this.margin.right;
    }
  },
  methods: {
    draw: function () {
      const maxValue = d3.max(this.matrix.data) as number;
      const x = d3.scaleBand().domain(this.matrix.x).rangeRound([this.margin.left, this.vizWidth]);
      const y = d3.scaleBand().domain(this.matrix.y).rangeRound([this.margin.top, this.vizWidth]);
      const legendScale = d3.scaleLinear().domain([maxValue, 0]).rangeRound([this.margin.top, this.vizWidth]);
      const yAxis = (g: Selection<any, any, any, any>) => g.attr("transform", `translate(${this.margin.left},0)`).call(d3.axisLeft(y).ticks(this.matrix.y.length).tickSizeOuter(0)); // eslint-disable-line @typescript-eslint/no-explicit-any
      const xAxis = (g: Selection<any, any, any, any>) => g.attr("transform", `translate(0,${this.height - this.margin.bottom})`).call(d3.axisBottom(x).ticks(this.matrix.x.length).tickSizeOuter(0)); // eslint-disable-line @typescript-eslint/no-explicit-any
      const legendAxis = (g: Selection<any, any, any, any>) => g.attr("transform", `translate(${this.width - 30},0)`).call(d3.axisRight(legendScale).ticks(10).tickSizeOuter(0)); // eslint-disable-line @typescript-eslint/no-explicit-any

      this.y = y;
      this.x = x;
      this.bandwidth = x.bandwidth();
      this.color = d3.scaleSequentialSqrt([0, maxValue], d3.interpolateBlues);

      const svg = d3.select(this.$el.querySelector("svg"));
      const halfStepHeight = this.bandwidth / 8;
      svg.select("#yAxis").selectAll("*").remove();
      svg.select("#yAxis").call(yAxis).selectAll("text").attr("text-anchor", "end").attr("transform", `rotate(-90),translate(${halfStepHeight}, -15)`).text((d: any) => d.replace("Actual", "")); // eslint-disable-line @typescript-eslint/no-explicit-any
      svg.select("#xAxis").selectAll("*").remove();
      svg.select("#xAxis").call(xAxis).selectAll("text").text((d: any) => d.replace("Predicted", "")); // eslint-disable-line @typescript-eslint/no-explicit-any
      svg.select("#legendAxis").selectAll("*").remove();
      svg.select("#legendAxis").call(legendAxis).selectAll("path").attr("stroke", "transparent");
      svg.selectAll("text").attr("font-size", "1.33em");

      svg.select("#yAxis").append("g").attr("transform", `rotate(-90),translate(-${this.bandwidth * 1.5 + 20}, -30)`).append("text").text("Actual").attr("fill", "#000").attr("font-size", "1.5em").attr("font-weight", "bold");
      svg.select("#xAxis").append("g").attr("transform", `translate(${this.bandwidth * 1.5 + 50}, 40)`).append("text").text("Predicted").attr("fill", "#000").attr("font-size", "1.5em").attr("font-weight", "bold");
    },
    resize: function () {
      const rect = this.$el.getBoundingClientRect();
      this.vizWidth = Math.min(600, Math.max(rect.width - 200, 200));
    }
  },
  watch: {
    width: function () {
      this.draw();
    },
    height: function () {
      this.draw();
    },
    matrix: function () {
      this.draw();
    }
  },
  mounted: function () {
    window.addEventListener("resize", this.resize);
    this.resize();
    this.draw();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resize);
  }
});

