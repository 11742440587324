import Vue from "vue";
import { DirectiveBinding } from "@/interfaces/vue";
import { FomanticElement } from "@/interfaces/fomantic";

const Dropdown: Vue.DirectiveOptions = {
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    setTimeout(() => {
      ($(el) as FomanticElement).dropdown({
        allowAdditions: binding.modifiers.addable,
        forceSelection: binding.modifiers.clearable !== true,
        clearable: binding.modifiers.clearable
      });
    }, 0);
  } as Vue.DirectiveFunction,
  update: function (el: HTMLElement, binding: DirectiveBinding) {
    let update = false;
    if ($(el).attr("multiple") && binding.value !== undefined) {
      binding.value.forEach((v: string) => { update = update || binding.oldValue.indexOf(v) < 0; });
      update = update || binding.value.length !== binding.oldValue.length;
    } else {
      update = binding.value !== binding.oldValue;
    }
    if (update) {
      ($(el) as FomanticElement).dropdown("set exactly", binding.value);
    }
  } as Vue.DirectiveFunction
};

Vue.directive("dropdown", Dropdown);
