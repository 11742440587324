




































import Vue from "vue";
import { Forecast } from "@/interfaces/forecast";

export default Vue.extend({
  props: {
    baselineOtcPrices: Array as () => Forecast[],
    traderPredictions: Array as () => Forecast[]
  }
});
